.wrapper {
  z-index: 1;
  margin: -247px auto 0;
  max-width: 720px;
  background: #fff;
  box-shadow: var(--shadow-s);

  @media (--tablet) {
    margin-top: -147px;
  }

  & :global(.search-widget) {
    margin: 0;
    box-shadow: 0;
  }
}
