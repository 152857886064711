.accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-bottom: 1px solid var(--color-neutral-300);
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--color-neutral-700);

  &.accordionOpen {
    padding-bottom: var(--spacing-6);
  }
}

.titleIcon {
  gap: var(--spacing-4);
}

.header {
  text-align: left;
  flex-grow: 1;
  width: 100%;
  cursor: pointer;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: var(--spacing-4) 0;

  -webkit-box-align: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-pack: center;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;

  outline-color: var(--color-secondary-500);
  appearance: auto;
  border: 0;
  margin: 0;
  border-radius: 0;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  display: flex;

  &.headerOpen {
    margin-bottom: var(--spacing-1);

    & .headerArrow {
      transform: rotate(180deg);
    }
  }

  &.headerDisabled {
    cursor: default;
    outline: none;
    color: var(--color-neutral-300);

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }

  & .headerArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-6);
    height: var(--spacing-6);

    margin-left: var(--spacing-6);

    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(0deg);
  }
}

.toggleText {
  font-size: 14px;
  text-decoration: underline;
}
