.reportStatusWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: var(--spacing-8);
  & button {
    margin-top: var(--spacing-8);
  }
  > div {
    width: 100%;
  }
}

.ctasWrapper {
  display: flex;
  gap: var(--spacing-3);
  justify-content: center;
  flex-direction: column-reverse;

  @media (--tablet) {
    flex-direction: row;
  }
}

.error {
  color: var(--colour-highlight-700, #e24529);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 32px 0px 20px;
}
