.modalContainer {
  @media (--tablet-l) {
    width: 800px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  padding: var(--spacing-5) 0 150px;

  @media (--tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: var(--spacing-8) 0 128px;
  }

  @media (--tablet-l) {
    padding: var(--spacing-8) 0;
  }
}

.title {
  margin: 0;
  flex: 0 0 100%;

  @media (--tablet) {
    flex: 0 0 75%;
  }
}

.colL {
  padding: var(--spacing-6) var(--spacing-5);
  background-color: var(--color-primary-50);

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-3);
    padding-top: var(--spacing-6);
  }

  & div[role='listitem'] {
    background-color: var(--color-primary-50);
  }

  @media (--tablet) {
    flex: 0 1 calc(50% - 10px);
  }
}

.colR {
  & .formTitle {
    margin-top: 0;
    margin-bottom: var(--spacing-5);
  }

  & .disclaimer {
    margin-top: var(--spacing-5);
    line-height: 16px;
  }

  @media (--tablet) {
    flex: 0 1 calc(50% - 10px);
    padding: var(--spacing-6) 0;
  }
}

.ctaSection {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--spacing-6) var(--spacing-5);
  width: 100%;
  box-shadow: 0px 0px 13px 0px rgba(38, 38, 38, 0.14);
  background: #fff;

  & p {
    margin-bottom: var(--spacing-3);
  }

  & button {
    width: 100%;
  }

  @media (--tablet) {
    display: flex;
    align-items: flex-end;
    gap: var(--spacing-5);

    & p {
      margin: 0;
    }

    & p,
    & button {
      flex: 0 1 calc(50% - 10px);
    }
  }

  @media (--tablet-l) {
    position: static;
    margin: 0 -40px -32px;
    width: 800px;
  }
}

.formSubmitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: var(--spacing-8);

  & button {
    margin-top: var(--spacing-8);
    width: 100%;
  }

  @media (--tablet) {
    & button {
      width: auto;
    }
  }
}

.filterCtaWrapper {
  padding: 0 var(--spacing-5) var(--spacing-4);
  width: 100%;
  border-bottom: solid 1px var(--color-neutral-50);

  &.noPadding {
    padding: 0;
  }
}

.pageBellButtonWrapper {
  color: inherit;
}

.pageCtaWrapper {
  padding: var(--spacing-10) 0 var(--spacing-4);
  width: 100%;
  border-top: solid 1px var(--color-neutral-300);
  content-visibility: auto;

  & h4 {
    margin: 0 0 var(--spacing-2);
    line-height: normal;
  }

  button {
    margin-top: var(--spacing-3);
    width: 100%;
  }

  @media (--tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: var(--spacing-16) 0 var(--spacing-6);

    & span {
      grid-column-start: 1;
    }

    & button {
      justify-self: flex-end;
      margin-top: 0;
      width: auto;
      grid-column-start: 2;
      grid-row-start: 1;
    }
  }
}
