.wrapper {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);
  --currentContainerWidth: calc(100vw - (2 * var(--sidePadding)));

  @media (--tablet) {
    --internalGap: var(--spacing-6);
  }

  @media (--tablet-l) {
    --internalGap: var(--spacing-8);
  }

  @media (--desktop-m) {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
  }

  @media (--desktop-l) {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
  }

  position: relative;
  left: calc(var(--sidePadding) * -1);
  right: calc(var(--sidePadding) * -1);
  width: 100vw;
}
