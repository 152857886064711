.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.icon {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;

  & img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  & svg {
    color: var(--icon-color);
    fill: var(--icon-color);
    height: 40px;
    width: 40px;

    & * {
      color: var(--icon-color);
      fill: var(--icon-color);
    }
  }
}
.tile {
  flex: 0 0 100%;
  margin-right: 0;
  margin-bottom: var(--spacing-6);

  @media (--tablet) {
    && {
      flex: 1;
      margin-right: var(--spacing-10);
      margin-bottom: 0;
    }
  }

  && {
    && {
      padding: var(--spacing-8);
    }
  }

  &:last-child {
    margin-right: 0;
  }

  & h5 {
    margin: var(--spacing-3) 0;
  }
  & a {
    color: var(--color-primary-500);
    display: flex;
  }
}
