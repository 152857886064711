.bannerWrapper {
  display: grid;
  align-items: center;
  background-color: var(--color-sunbeam-blue-50);
  gap: var(--spacing-8);
  grid-template-columns: 1fr;
  padding: var(--spacing-6);

  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
    & .desc {
      grid-column: 1 / -1;
    }
  }

  @media (--tablet-l) {
    grid-template-columns: 0fr 1fr 1fr 1fr 0fr;
    gap: var(--spacing-16);

    & .image {
      grid-column: 2 / 3;
    }

    & .bannerTitleWithLink {
      grid-column: 3 / 4;
    }

    & .desc {
      grid-column: 4 / 5;
    }
  }

  & .image {
    height: 110px;
    position: relative;
    width: 100%;
  }

  & .bannerTitleWithLink {
    display: inline;

    & .bannerTitle {
      margin: 0;
      & .cta {
        color: var(--color-primary-500);
        font-size: 20px;
        padding-left: 6px;
        line-height: normal;
      }
    }

    @media (--tablet) {
      & .bannerTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 110px;
        justify-content: space-between;

        & .cta {
          padding-left: 0;
        }
      }
    }

    @media (--tablet-l) {
      & .bannerTitle {
        gap: 10px;
        height: auto;
      }
    }
  }
  & .desc {
    margin: 0;
  }
}
