.wrapper {
  padding: var(--spacing-3) var(--spacing-3);
  background-color: var(--color-primary-50);
  margin: var(--spacing-5) 0 0;
  line-height: 1.4;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  cursor: pointer;
  align-items: center;
}
