/* Icon */
.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 0;

  &:only-child {
    margin: 0;
  }
}
