.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.btnWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: max-content;
  width: 100%;
}

.btn {
  grid-row-start: buttons;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--spacing-3);
  width: auto;
  height: 56px;
  border: none;
  background: var(--color-primary-50);
  color: var(--color-neutral-700);
  font-size: 14px;
  box-shadow: 0px -3px 8px 0px #00293714 inset;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 200;

  & > svg {
    margin-right: var(--spacing-2);
  }

  &:active {
    font-weight: 700;
  }

  &[aria-selected='true'] {
    background: #fff;
    box-shadow: none;
    font-weight: 700;

    &:active {
      font-weight: 700;
      background: var(--color-neutral-100);
    }
  }

  &:disabled {
    background: var(--color-primary-50);
    color: var(--color-neutral-300) !important;
    cursor: not-allowed;
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      background: var(--color-primary-100);
    }

    &[aria-selected='true'] {
      &:hover,
      &:focus {
        background: var(--color-neutral-50);
      }
    }
  }
}

.contentWrapper {
  display: none;
  width: 100%;

  &:global(.selected-panel) {
    display: block;
  }
}
