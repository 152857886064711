.campaignHeader {
  max-width: 870px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: var(--spacing-6);
  margin-bottom: var(--spacing-5);

  @media (--tablet) {
    flex-direction: row;
  }
}

.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
    padding-bottom: 14px;
  }
}

.modalBody {
  overflow: auto;
}

.campaignTitle {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: var(--spacing-3);
  padding: var(--spacing-5);
  margin-top: var(--spacing-6);

  @media (--mobile) {
    & p {
      font-size: 18px;
    }
  }
  @media (--tablet) {
    & p {
      font-size: 26px !important;
    }
  }
}

.campaignDescription {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  padding: var(--spacing-5) var(--spacing-5) var(--spacing-10) var(--spacing-5);
  min-height: 110px;
}
.campaignSelectorWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  align-items: flex-start;
  padding: var(--spacing-4);
  margin-top: 10px;
  cursor: pointer;
  line-height: 22px;
  background-color: var(--color-sunbeam-blue-50);

  & svg {
    margin-top: var(--spacing-1);
    font-size: 20px !important;
  }

  @media (--tablet) {
    width: 100%;
  }

  @media (--tablet-l) {
    width: 104%;
  }
  @media (--desktop) {
    width: auto;
  }
  & .layoutBody {
    & .layoutText {
      display: none;
      @media (--tablet) {
        display: inline;
        text-decoration: underline;
      }
    }
    & .layoutTextMobile {
      text-decoration: underline;
      @media (--tablet) {
        display: none;
      }
    }
  }
}

.campaignTitle strong,
.campaignDescription strong {
  background-color: var(--color-primary-500);
  color: #fff;
}

.campaignTitle[data-color='mint'],
.campaignDescription[data-color='mint'] {
  background-color: var(--color-heycar-mint-500);
}

.campaignTitle[data-color='mint_light'],
.campaignDescription[data-color='mint_light'] {
  background-color: var(--color-heycar-mint-200);
}

.campaignTitle[data-color='mint_green'],
.campaignDescription[data-color='mint_green'] {
  background-color: var(--color-heycar-mint-100);
}

.campaignTitle[data-color='mica_blue'],
.campaignDescription[data-color='mica_blue'] {
  background-color: var(--color-sunbeam-blue-600);
}

.campaignTitle[data-color='sunbeam_blue'],
.campaignDescription[data-color='sunbeam_blue'] {
  background-color: var(--color-sunbeam-blue-200);
}

.campaignTitle[data-color='mustang_yellow'],
.campaignDescription[data-color='mustang_yellow'] {
  background-color: var(--color-mustang-yellow-400);
}

.campaignTitle[data-color='light_yellow'],
.campaignDescription[data-color='light_yellow'] {
  background-color: var(--color-mustang-yellow-100);
}

.campaignTitle[data-color='ferrari_red'],
.campaignDescription[data-color='ferrari_red'] {
  background-color: var(--color-old-ferrari-red-500);
}

.campaignTitle[data-color='light_red'],
.campaignDescription[data-color='light_red'] {
  background-color: var(--color-old-ferrari-red-200);
}

.campaignLayoutPadding {
  display: flex;
  width: 100%;
}
