.wrapper {
  background: white;

  & code {
    font-family: var(--source-code-pro), 'Roboto', sans-serif !important;
    font-size: 12px !important;
  }

  & :global(.property) {
    color: var(--color-mica-blue-600);
    font-weight: 600;
  }

  & :global(.operator) {
    color: var(--color-mica-blue-800);
  }

  & :global(.punctuation) {
    color: black;
  }

  & :global(.string) {
    color: var(--color-heycar-mint-600);
  }

  & :global(.boolean) {
    color: var(--color-old-ferrari-red-700);
  }
}
