.carFinanceAd {
  min-height: 400px;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 124px;
  }
  & img {
    height: auto;
    width: 100%;
    max-width: 300px;
  }
}

.pdpMobileDmpu {
  display: block;
  margin-top: var(--spacing-6);
  background-repeat: repeat;
  background-size: 35%;

  && [class^='Ad_wrapper'] {
    margin-top: 0;
  }

  @media (--tablet-l) {
    background: none;
    display: none;
  }
}
