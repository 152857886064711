.layout {
  width: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
}

.carTypes {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: var(--spacing-2);

  @media (--tablet) {
    gap: var(--spacing-3);
  }

  @media (--tablet-l) {
    gap: var(--spacing-4);
    display: flex;
  }

  @media (--desktop) {
    gap: var(--spacing-6);
  }

  & a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }
}

.carType {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-1);
  background: var(--color-tarmac-grey-50);
  width: 156px;
  height: 138px;
  text-align: center;
  text-decoration: none;
  transition: 0.1s;
  user-select: none;

  &:hover {
    box-shadow: 0px var(--spacing-1) var(--spacing-3) rgba(38, 38, 38, 0.08);
  }

  &:active {
    background: var(--color-tarmac-grey-100);
  }

  @media (--tablet) {
    width: 232px;
    height: 228px;
  }

  @media (--tablet-l) {
    width: 310px;
  }
}

.image {
  height: 38px;
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  @media (--tablet) {
    height: 64px;
    margin-bottom: 24px;
  }
}

.carTypeTitle {
  font-weight: 500;
}
