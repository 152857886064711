.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-mica-blue-50);
  padding: var(--spacing-6);
}

.tilesWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  gap: var(--spacing-6);

  & > article {
    flex: 1 1 100%;
    max-width: calc(100%);
  }

  @media (min-width: 630px) {
    & > article {
      flex: 1 1 calc(50% - 12px);
      max-width: calc(50% - 12px);
    }
  }

  @media (--tablet-l) {
    gap: var(--spacing-6);

    & > article {
      flex: 1 1 calc(33% - 14px);
      max-width: calc(33% - 13px);
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-10);
}

.title {
  margin-bottom: 0 !important;
}
