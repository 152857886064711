.callDealerForm {
  padding: var(--spacing-8) 0;
  display: flex;
  width: 736px;
  align-items: center;

  & button {
    min-width: 280px;
  }
}

.dealerInfo {
  display: flex;
  gap: var(--spacing-3);
  & p {
    margin: 0;
  }
}

.modalContainer {
  padding-bottom: var(--spacing-8);
}

.topPriority {
  order: 1;
}

.error {
  color: var(--color-red-700);
  font-size: 12px;
  line-height: 18px;
}
