.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  max-height: 40vh;
  height: 40vh;
  position: relative;
}

.sectionLabel {
  position: absolute;
  top: 0;
  left: 0;
  background: #f0f0f0;
  padding: var(--spacing-1) var(--spacing-3);
  font-size: 10px;
  font-weight: 600;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-4);
}

.block {
  width: 100%;
  padding: var(--spacing-4);
  padding-top: var(--spacing-9);

  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  border-left: 1px solid #f0f0f0;
  overflow-y: auto;
}

.fields {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}

.fieldGroup {
  display: flex;
  flex-direction: row;

  width: 100%;

  font-family: var(--source-code-pro);
  font-weight: 600;

  &:nth-child(2n + 1) {
    background: #f7f7f7;
  }

  &:nth-child(1) {
    * {
      background: #f0f0f0;
      font-weight: 700;
      font-size: 10px;
      color: black;

      .required {
        color: var(--color-neutral-400);
      }
    }
  }
}

.field {
  flex: 0 0 20%;
  max-width: 20%;
  overflow: auto;
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  padding: var(--spacing-2) var(--spacing-3);
  font-weight: 300;

  &:nth-child(1) {
    color: var(--color-mica-blue-600);
    font-weight: 600;
  }

  &:nth-child(2) {
    color: var(--color-heycar-mint-600);
    font-weight: 600;
  }

  &:nth-child(3) {
    font-weight: 600;
  }

  &:nth-child(4) {
    color: var(--color-neutral-400);
    font-weight: 600;
    flex: 0 0 40%;
    max-width: 40%;
  }

  & * {
    font-size: 12px;
    line-height: 1.4;
  }
}

.required {
  color: red;
}
