.modalWrapper {
  padding: var(--spacing-6) var(--spacing-3) var(--spacing-10);
}

.title {
  text-align: center;
  margin-bottom: var(--spacing-4);
}

.title {
  margin: var(--spacing-4) 0 0;
}

.description {
  margin: var(--spacing-2) 0;
  > p {
    margin: 0;
  }
}
