.wrapper {
  max-width: 1312px;
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--spacing-6);

  @media (--tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & > a {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: var(--color-sunbeam-blue-50);
    padding: var(--spacing-6);
    margin-bottom: var(--spacing-3);
    text-decoration: none;
    color: black;

    @media (--tablet) {
      width: calc(50% - 12px);
    }

    @media (--desktop) {
      width: calc(25% - 18px);
    }

    & > .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 148px;
      height: 148px;
      margin: 0 auto;

      margin-bottom: var(--spacing-5);
      position: relative;

      & > img {
        width: var(--spacing-10);
        object-fit: contain;
        object-position: center;
        border-radius: 50%;
      }
    }

    & > .content {
      display: flex;
      flex-direction: column;
      align-items: start;
      line-height: 26px;
    }
  }
}
