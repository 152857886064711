.featureHeader {
  margin: 20px 0;
}

.featureLists {
  list-style: disc;
  padding-left: 24px;
  && {
    margin: 0;
  }
}
