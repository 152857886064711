.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 60vh;
  border-bottom: 1px solid #f0f0f0;
  overflow-y: auto;

  position: relative;
}

.code {
  padding: var(--spacing-4);
  padding-top: var(--spacing-7);
  overflow: auto;
}

.sectionLabel {
  position: absolute;
  top: 0;
  left: 0;
  background: #f0f0f0;
  padding: var(--spacing-1) var(--spacing-3);
  font-size: 10px;
  font-weight: 600;
}
