.wrapper {
  && {
    position: relative;
    cursor: pointer;
    padding: var(--spacing-1);
    text-align: center;

    &[data-color='mint'] {
      background-color: var(--color-heycar-mint-500);

      & strong {
        background-color: var(--color-primary-500);
        color: #fff;
      }
    }

    &[data-color='mint_light'] {
      background-color: var(--color-heycar-mint-200);

      & strong {
        background-color: var(--color-tertiary-400);
      }
    }

    &[data-color='mica_blue'] {
      background-color: var(--color-primary-500);
      color: #fff;

      & strong {
        background-color: var(--color-secondary-500);
        color: #000;
      }
    }

    &[data-color='sunbeam_blue'] {
      background-color: var(--color-tertiary-500);

      & strong {
        background-color: var(--color-secondary-300);
      }
    }

    & p {
      font-size: 14px;
      font-weight: 500;
    }

    & strong {
      font-style: normal;
    }

    & em {
      font-style: normal;
      text-decoration: underline;
    }

    & a {
      color: var(--color-tarmac-grey-900);
      text-decoration: underline;
    }

    &.deep {
      padding: var(--spacing-2) var(--spacing-1);

      & p {
        font-weight: 700;
      }
    }

    @media (--tablet) {
      & p {
        font-size: 16px;
      }
    }

    @media (--desktop) {
      &.deep {
        padding: var(--spacing-5) var(--spacing-1);
        & p {
          font-size: 18px;
        }
      }
    }
  }
}

.modalWrapper {
  && {
    padding: var(--spacing-10) 0;

    & .heading,
    & .content {
      padding: 20px;
    }

    & h3 {
      margin: 0;
    }

    &[data-color='mint'] {
      & .heading {
        background-color: var(--color-heycar-mint-400);
      }
      & .content {
        background-color: var(--color-heycar-mint-100);
      }
    }
  }
}
