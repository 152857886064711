.modalHeight {
  display: flex;

  @media (--tablet-l) {
    display: none;
  }
}

.modalHeader {
  flex-direction: column;
  border-bottom: none !important;
  justify-content: center !important;
  padding: var(--spacing-6) !important;

  & button {
    position: absolute;
    top: 33px;
    left: 16px;
    transform: translateY(-50%);
  }
}

.modalBody {
  max-height: 800px;
  overflow-y: auto;
  margin: var(--spacing-4) 0;
}

.mobileViewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-300-disabled, #b5b5b5);
  font-size: 16px;
  font-weight: 500;
  padding: var(--spacing-4) 0;
  align-items: center;
  & svg {
    [data-tenant='rac'] & {
      fill: var(--color-primary-500);
      color: var(--color-primary-500);
    }
  }

  @media (--tablet-l) {
    display: none;
  }

  & .titleWithIcon {
    display: flex;
    align-items: center;
    gap: var(--spacing-4);
  }
}
