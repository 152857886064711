.rangeContainer {
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.rangeLabel {
  font-size: 12px;
}

.currentValue {
  width: 55px;
  height: 19px;
  padding: var(--spacing-1);
  background-color: var(--color-primary-50);
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}
