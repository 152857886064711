/*
* Base
*/
:where(.button) {
  font-family: var(--font-family-system);
  padding: var(--spacing-1) var(--spacing-6);
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0;
  border: 0;
  outline: none;
  box-shadow: none;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  line-height: 1.2;
  border-style: solid;
  white-space: nowrap;
  gap: var(--spacing-2);

  &:disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  [data-tenant='rac'] & {
    border-radius: var(--border-radius);
  }
}

:where(.iconLeft) {
  margin-left: -4px;
}

:where(.iconRight) {
  margin-right: -4px;
}

/* Full width */
:where(.fullWidth) {
  display: flex;
  width: 100%;
}

/* Loading */
:where(.loading .text),
:where(.loading .icon) {
  opacity: 0;
}

:where(.loading) {
  pointer-events: none;

  &:hover {
    background: currentColor;
  }
}

:where(.loader) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
