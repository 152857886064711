.overview {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: var(--spacing-10) 0;

  @media (--tablet-l) {
    margin: var(--spacing-12) 0 var(--spacing-6);
  }

  & [data-accordion-component='AccordionItemPanel'] {
    width: 100%;
  }
}

.overviewOnly {
  margin: var(--spacing-10) 0 0;

  @media (--tablet-l) {
    margin: var(--spacing-12) 0 0;
  }
}

.divider {
  border-top: 1px solid var(--color-tarmac-grey-300);
}

.overviewHeader,
.featureDescription,
.featureSubHeader {
  && {
    margin: 0;
  }
}
.featureSubHeader {
  padding-bottom: var(--spacing-5);
}

.cta {
  margin-top: var(--spacing-6);
}

.keyInfo {
  display: flex;
  flex-wrap: wrap;
  margin: var(--spacing-8) 0;
  && {
    padding: 0;
  }
}

.keyInfoItem {
  font-weight: 500;
  flex: 1 1 calc(50% - 8px);
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);

  & svg {
    [data-tenant='rac'] & {
      fill: var(--color-primary-500);
    }
  }

  /* Hide the last 2 items on mobile */
  &:nth-child(n + 7) {
    display: none;
    @media (--tablet) {
      display: flex;
    }
  }

  @media (--tablet) {
    flex: 0 0 calc(25% - 8px);
  }
}

.twoColumnContainer {
  display: flex;
  flex-direction: column;

  @media (--tablet-l) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 42px;
  }
}

.info {
  flex: 1;
  @media (--tablet-l) {
    flex-direction: row;
  }
}

.disclaimer {
  flex: 1 1 100%;
  font-size: 12px;
  color: var(--color-neutral-500);
  margin: var(--spacing-5) 0;

  @media (--tablet-l) {
    margin-top: var(--spacing-4);
    margin-bottom: 0;
  }
}

.hideSectionForTblt {
  && {
    display: none;

    @media (--tablet-l) {
      display: flex;
    }

    & svg {
      [data-tenant='rac'] & {
        fill: var(--color-primary-500);
        color: var(--color-primary-500);
      }
    }
  }
}

.consumptionColumnHeader {
  display: flex;
  padding-bottom: var(--spacing-3);
}

.info:nth-child(2) .consumptionColumnHeader {
  padding-top: var(--spacing-10);
  @media (--tablet-l) {
    padding-top: 0;
  }
}
