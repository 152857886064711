/*
* Base
*/
.badge {
  font-family: var(--font-family-system);
  font-size: 14px;
  padding: var(--spacing-1) var(--spacing-2);
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration: none;
  border-style: solid;
  font-weight: var(--font-weight-medium);
  line-height: 24px;
}

.leftIcon {
  padding-right: var(--spacing-1);
  display: flex;
}

.inline {
  font-size: 12px;
  border-radius: var(--spacing-1);
  padding: var(--spacing-1) var(--spacing-1);
  line-height: 12px;
}
