.wrapper {
  display: flex;
  flex-direction: column;

  .error {
    border-color: var(--color-red-700);
  }

  & input {
    accent-color: #303030;
    transform: scale(1.5);
    margin: 7px;
  }

  & .labelWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & > svg {
      margin-top: var(--spacing-2);
      cursor: pointer;
    }
  }

  & label {
    font-size: 14px;
    font-weight: 400;
    margin-right: 7px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    width: 100%;

    &:disabled + label {
      cursor: default;
    }
  }

  & span {
    margin-left: 3px;
  }

  &:disabled + label {
    cursor: default;
    pointer-events: none;
  }

  & small {
    display: block;
    margin: 4px 0;
    color: var(--color-red-700);
  }
}
