/*
* Vars
*/

/*
* Sizes
*/
:where(.small) {
  min-height: var(--button-small-height);
  padding: var(--spacing-1) var(--spacing-3);
}

:where(.large) {
  min-height: var(--button-large-height);
}
