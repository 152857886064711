.preApprovalCard {
  margin-top: var(--spacing-14);

  &[data-variant='info'] {
    background-color: var(--color-mica-blue-50);
    color: var(--color-info-700);
    padding: 0 0 var(--spacing-4) var(--spacing-4);

    & .status {
      display: flex;
      flex-direction: column;
      text-align: left;

      & p {
        margin: 0;
      }

      & p:first-child {
        font-size: 14px;
      }

      & p:last-child {
        font-size: 14px;
      }
    }
  }

  .buttonGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: var(--spacing-3);
    margin: var(--spacing-6) var(--spacing-6) var(--spacing-2) 0;

    @media (--mobile) {
      grid-template-columns: 1fr;
      padding: var(--spacing-4) var(--spacing-4) 0 var(--spacing-1);
    }

    @media (--tablet-l) {
      max-width: 668px;
    }

    @media (--desktop-l) {
      max-width: 100%;
    }
  }

  .button {
    margin-bottom: var(--spacing-5);
    margin-left: 0;
    width: 100%;

    @media (--mobile) {
      &:first-child {
        order: 2;
      }
    }
  }
}
.preApprovalErrorIcon {
  text-align: center;
}

.preApprovalWrapper {
  text-align: left;
}
