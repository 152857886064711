.bcWrpr {
  font-family: var(--font-family-system);
  display: flex;
  flex-direction: row;
  color: var(--color-tarmac-grey-700);
  list-style: none;
  font-weight: var(--font-weight-medium);
  padding: 0;
  margin: 0;
  font-size: 12px;
  align-items: center;

  @media (--mobile) {
    flex-wrap: wrap;
  }

  & .ellip {
    cursor: pointer;

    @media (--tablet) {
      display: none;
    }
  }

  & .treeItm {
    display: none;

    @media (--tablet) {
      display: flex;
    }
  }

  &.fullView {
    & .ellip {
      display: none;
    }

    & .treeItm {
      display: flex;
    }
  }
}

.bcItm {
  display: flex;
  align-items: center;

  &:after {
    content: '|';
    padding: 0 4px;
  }
}

.bcItm:last-child {
  color: var(--color-neutral-500);
  &:after {
    content: '';
  }
}

.bcLink {
  text-decoration: none;
  color: var(--color-tarmac-grey-700);

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  line-height: normal;

  &:hover {
    color: var(--color-neutral-600);
    text-decoration: underline;
  }

  &:focus,
  &:active,
  &:visited {
    text-decoration: underline;
  }
}

.active {
  color: var(--color-neutral-500);
}

.disabled {
  color: var(--color-neutral-500);
}
