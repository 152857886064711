.modalContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    height: unset;
    width: 100%;
  }
  @media (--tablet-l) {
    height: unset;
    width: 800px;
  }
}

.modalBodyClassName {
  && {
    padding: 0;
  }
}

.dealerFormHeader {
  && {
    margin: 0;
  }

  span {
    font-weight: normal;
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalBody {
  overflow-y: auto;
  padding: 0 var(--spacing-5) 0 var(--spacing-5);

  @media (--tablet) {
    max-height: calc(100vh - 64px);
    padding: 0 var(--spacing-6) 0 var(--spacing-6);
  }

  @media (--tablet-l) {
    max-height: calc(100vh - 180px);
    padding: 0 var(--spacing-10) 0 var(--spacing-10);
  }
}

.captchaWrapper {
  padding-bottom: 150px;
}

.modalFooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: var(--spacing-6);
  background-color: var(--color-championship-white);
  box-shadow: 0px 0px 13px 0px rgba(38, 38, 38, 0.14);
  z-index: 9999;

  @media (--mobile) {
    padding: var(--spacing-3) var(--spacing-6) var(--spacing-6);
  }
}

.enrichmentWrapper {
  margin-bottom: var(--spacing-4);
}

.enrichmentContentWrapper {
  &.enrichmentBorder {
    margin-top: var(--spacing-4);
    border-top: 1px solid var(--color-neutral-300);
    border-bottom: 1px solid var(--color-neutral-300);
  }
}

.stroke {
  border: none;
  height: 1px;
  color: var(--color-mica-blue-100);
  background-color: var(--color-mica-blue-100);
  margin: var(--spacing-5) 0;

  @media (--tablet) {
    display: none;
  }
}
