.badge_primary {
  color: var(--color-championship-white);
  background-color: var(--color-primary-500);
}

.badge_secondary {
  color: var(--color-neutral-700);
  background-color: var(--color-secondary-500);
}

.badge_tertiary {
  color: var(--color-neutral-700);
  background-color: var(--color-tertiary-500);
}

.badge_error {
  color: var(--color-championship-white);
  background-color: var(--color-error-700);
}

.badge_neutral__light {
  color: var(--color-neutral-700);
  background-color: var(--color-championship-white);
}

.badge_mint_light {
  color: var(--color-primary-700);
  background-color: var(--color-heycar-mint-400);
}

.badge_mint {
  color: var(--color-primary-700);
  background-color: var(--color-secondary-500);
}

.badge_orange {
  color: var(--color-primary-700);
  background-color: var(--color-warning-400);
}

.badge_blue {
  color: var(--color-primary-700);
  background-color: var(--color-sunbeam-blue-500);
}

.badge_blue__dark {
  color: var(--color-championship-white);
  background-color: var(--color-mica-blue-700);
}
