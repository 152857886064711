.reserveVehicleCardWrapper {
  border: 1px solid var(--color-tarmac-grey-500);
  padding: var(--spacing-5);
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-3);
}

.titleWrapper {
  min-width: 144px;
}

.titleWrapper.fullWidth {
  width: 100%;
}

.footerWrapper {
  margin-top: var(--spacing-3);

  span {
    cursor: pointer;
  }
}

.modalContainer {
  @media (--tablet-l) {
    height: 500px;
    overflow: auto;
  }
}

.modalBody {
  overflow: auto;
}
