/*
* Base
*/
.chip {
  font-family: var(--font-family-system);
  font-size: 14px;
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration: none;
  padding: 5px var(--spacing-3);
  border: solid 1px var(--color-neutral-500);
  font-weight: 500;
  color: var(--color-neutral-700);
  background-color: #fff;
}

/*
* states
*/

.chip:hover {
  border-color: var(--color-neutral-700);
}

.chip.disabled {
  color: var(--color-neutral-300);
  background-color: var(--color-neutral-50);
  border-color: var(--color-neutral-300);
  cursor: not-allowed;

  &:hover {
    color: var(--color-neutral-300);
    border-color: var(--color-neutral-300);
    background-color: var(--color-neutral-50);
  }
}

.chip:active {
  border-color: var(--color-neutral-700);
}

.chipSelected {
  background-color: var(--color-primary-50);
  color: var(--chip-selected-color);
  border: 1px solid var(--chip-selected-border-color) !important;
}

.chipSelected:hover {
  background-color: var(--color-primary-100);
}

.chipSelected:active {
  background-color: var(--color-primary-200);
}

/*
* icon
*/

.chipCloseIcon {
  display: flex;
  margin-left: var(--spacing-2);
  cursor: pointer;
}

.chipCloseIcon svg {
  font-size: 16px !important;
}

.leftIcon_choice {
  display: flex;
  margin-right: var(--spacing-2);
}

.leftIcon_choice svg {
  font-size: 19px !important;
}

.leftIcon_filter svg {
  font-size: 16px !important;
}

.leftIcon_filter {
  display: flex;
  font-size: 16px !important;
  margin-right: var(--spacing-3);
}

/*
* Choice
*/

.choice {
  height: var(--spacing-12);
  font-size: 16px;
}

/*
* Filter
*/

.filter {
  height: var(--spacing-8);
}
