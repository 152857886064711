.infoCard {
  display: flex;
  gap: var(--spacing-6);
  flex-direction: column;
  padding: 0;

  @media (--tablet-l) {
    padding: var(--spacing-6) 0 var(--spacing-10);
  }
  & h4 {
    margin: 0 0 var(--spacing-4);
  }
}
.detailsList {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  & li {
    gap: var(--spacing-3);
    align-items: center;
    display: flex;
    padding: 0 0 var(--spacing-6);
  }
}

.cta {
  align-self: flex-start;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
}

.modalBody {
  overflow: auto;
  height: 100%;
}
