.wrapper {
  --content-gap: var(--spacing-6);
  position: relative;
  text-decoration: none;
  display: block;
  height: 464px;
  & > .heroBannerContainer {
    display: block;
    height: 100%;
    @media (--mobile) {
      padding: var(--spacing-16) 0 var(--spacing-8);
    }
  }

  @media (--tablet-s) {
    height: 300px;
  }
  @media (--tablet-l) {
    height: 380px;
  }
  @media (--desktop) {
    height: 380px;
  }
  [data-tenant='rac'] & {
    --content-gap: var(--spacing-1);
  }
}

.header,
.subHeader {
  margin: 0;
  display: inline;
  align-self: center;
  justify-self: flex-start;
  -webkit-box-decoration-break: clone;
}
& .subHeader {
  margin-bottom: var(--spacing-6);
}

.heroContentWrapper {
  position: relative;
  z-index: 1;
  height: 100%;
  gap: var(--content-gap);
  display: grid;
  align-content: flex-start;
  @media (--tablet-s) {
    align-content: center;
  }
}

.ctaGroup {
  gap: var(--spacing-2) var(--spacing-6);
  display: flex;
  flex-direction: column;
  @media (--tablet-s) {
    flex-direction: row;
  }
}

.imageContainer {
  --internalGap: var(--spacing-5);
  --maxPaddingWidth: min(1340px, 100vw - (2 * var(--internalGap)));
  --sidePadding: calc((100vw - var(--maxPaddingWidth)) / 2);

  position: absolute;
  top: 0;

  width: 100vw;
  left: calc(var(--sidePadding) * -1);

  @media (--tablet) {
    --internalGap: var(--spacing-6);
  }

  @media (--tablet-l) {
    --internalGap: var(--spacing-8);
  }

  @media (--desktop-m) {
    --internalGap: var(--spacing-8);
    --maxPaddingWidth: min(1373px, 100vw - (2 * var(--internalGap)));
  }

  @media (--desktop-l) {
    --internalGap: var(--spacing-16);
    --maxPaddingWidth: min(1312px, 100vw - (2 * var(--internalGap)));
  }
}

.imageContainerSkltn {
  height: 467px;

  @media (--tablet) {
    height: 300px;
  }

  @media (--tablet-l) {
    height: 380px;
  }
}

.image {
  width: 100vw;
  height: 464px;
  object-fit: cover;

  @media (--mobile) {
    height: 464px;
  }
  @media (--tablet-s) {
    height: 300px;
  }
  @media (--tablet-l) {
    height: 380px;
  }
  @media (--desktop) {
    height: 380px;
  }
}
