.wrapper {
  width: 100%;
  display: flex;
  gap: var(--spacing-6);
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-6);
  background-color: var(--color-primary-50);
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);

  & p {
    margin: 0;
    padding: 0;
  }

  & button {
    width: 100%;

    @media (--tablet) {
      width: 250px;
    }
  }
}

.avatar {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & img {
    width: 50px;
  }
}

.heading {
  text-align: center;
}

.features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3) var(--spacing-10);
  justify-content: center;
  width: 100%;
  max-width: 960px;

  @media (--tablet) {
    flex-direction: row;
    align-items: center;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: var(--spacing-3);
}
