.wrapper {
  background-color: var(--color-mica-blue-50);
  padding: 0 var(--spacing-5) var(--spacing-10);
  margin-bottom: var(--spacing-10);

  @media (--tablet) {
    padding: 0 var(--spacing-6) var(--spacing-10);
  }
  @media (--tablet-l) {
    padding: 0 0 var(--spacing-10);
  }
  & .innerCmpnt {
    padding: var(--spacing-6) 0;
  }
}

.innerCmpnt {
  & h2 {
    margin-top: 0;
  }
}
