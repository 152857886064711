.extraUspItems {
  list-style: none;
  margin: 0;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .titleContainer {
    display: flex;
    gap: 10px;
  }

  & li {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;

    @media (--tablet-l) {
      flex-direction: row;
    }
  }

  & .icon {
    position: relative;
    aspect-ratio: 1/1;
    height: 36px;
  }

  & .typography {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & .desktopTitle {
    margin: 0;
    display: none;

    @media (--tablet-l) {
      display: block;
    }
  }

  & .mobileTitle {
    margin: 0;
    display: flex;
    align-items: center;
    @media (--tablet-l) {
      display: none;
    }
  }

  & .description {
    margin: 0;
  }
}
