.fieldGroup {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 16px 0 24px;
}

.field {
  &[data-width='full'] {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &[data-width='half'] {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  &[data-id='date'] {
    & input {
      max-width: 100%;
      padding-right: var(--spacing-4);
    }
  }
  & textarea {
    padding: var(--spacing-2) var(--spacing-4) !important;
  }
}

.universalFieldGroup {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  margin: 16px 0 24px;

  @media (--mobile) {
    grid-template-columns: 1fr 1fr;
  }
}

.universalField {
  @media (--mobile) {
    &[data-width='full'] {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &[data-width='half'] {
    grid-column: span 1;
    max-width: calc(50% - 6px);
  }

  &[data-width='double'] {
  }

  &[data-id='date'] {
    & input {
      max-width: 100%;
      padding-right: var(--spacing-4);
    }
  }
  & textarea {
    padding: var(--spacing-2) var(--spacing-4) !important;
  }
}
