.wrapper {
  overflow: hidden;
  margin: 0 auto;
  padding: var(--spacing-10) var(--spacing-5);

  & .title {
    margin: 0 0 var(--spacing-10);
  }

  @media (--tablet-l) {
    padding: var(--spacing-10) var(--spacing-8);
  }

  @media (--desktop) {
    padding-right: var(--spacing-16);
    padding-left: var(--spacing-16);
  }
}

.content {
  & [data-test-id='google-rating'] {
    margin-bottom: var(--spacing-8);
  }

  @media (--tablet-l) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & [data-test-id='google-rating'] {
      margin-right: var(--spacing-8);
      max-width: 220px;
    }
  }
}

.carousel {
  position: relative;
  overflow: hidden;
  margin: auto;
  padding: 0 var(--spacing-14);
  width: 320px;

  @media (--tablet) {
    width: 100%;
  }

  @media (--tablet-l) {
    width: calc(100% - 252px);
  }
}

.container {
  overflow: hidden;
  position: relative;
}

.reel {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease;
  transform: translate(0, 0);
}

.arrow {
  position: absolute;
  top: calc(50% - 16px);
  padding: 2px;
  width: 30px;
  height: 30px;
  border: solid 1px var(--color-neutral-400);
  border-radius: 50%;
  color: var(--color-neutral-400);
  opacity: 0.4;
  pointer: disabled;

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
  &.active {
    opacity: 1;
    pointer: auto;
  }
}

.review {
  padding: var(--spacing-4);
  width: 208px;
  min-width: 208px;

  & > blockquote {
    margin: 0;
    padding: 0;
    font-size: 11px;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-3);

    & > p {
      margin: var(--spacing-3) 0 0;
    }
  }
}

.reviewer {
  margin: var(--spacing-4) 0 0;
  font-size: 11px;
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-3);

  & span {
    font-weight: var(--font-weight-regular);
  }
}
