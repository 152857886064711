.wrapper {
  background-color: var(--color-warning-400);
  border-radius: var(--spacing-1);
  margin-right: var(--spacing-20);
  min-height: ;
  padding: var(--spacing-1) var(--spacing-2);
  position: absolute;
  top: var(--spacing-4);
  left: var(--spacing-4);

  & > p {
    font-size: 14px;
    margin: 0;
    min-height: 18px;

    @media (--mobile) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
}

.badges {
  position: absolute;
  top: var(--spacing-2);
  left: var(--spacing-2);
  z-index: 1;
  display: flex;
  gap: var(--spacing-2);

  & > div {
    border-radius: var(--spacing-1);
    line-height: 20px;
  }
}
