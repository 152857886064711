.container {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-10);
  margin-bottom: var(--spacing-10);
  align-items: space-between;
  justify-content: space-between;
  width: 100%;

  [data-service-consumer='native'] & {
    margin-bottom: 0;
  }

  @media (--tablet-l) {
    flex-direction: row;
    padding-top: var(--spacing-8);
  }

  @media (--desktop-l) {
    padding-top: var(--spacing-16);
  }

  & [class^='UspsItems_uspsPadding'] {
    order: 2;
    background-color: white;
    margin-top: var(--spacing-4);

    @media (--tablet-l) {
      order: unset;
      position: relative;
      z-index: 1;
    }

    &[data-scrolled='true'] {
      @media (--tablet-l) {
        top: 70px;
      }
      @media (--desktop-l) {
        top: 60px;
      }
    }
  }
}

.autobizBanner {
  background-color: var(--color-mica-blue-50);
  padding: var(--spacing-5);
  position: relative;
  & p {
    margin-top: -20px;
  }

  @media (--tablet) {
    padding: var(--spacing-7) var(--spacing-5);
    & p {
      margin: var(--spacing-5) 0;
      width: 45%;
    }
  }
}

.title {
  background-color: var(--color-mica-blue);
  color: #fff;
  font-size: 20px;
  margin: 0;
  padding: var(--spacing-3) var(--spacing-2);
  @media (--tablet) {
    width: fit-content;
  }
}

.image {
  height: auto;
  position: relative;
  left: 115px;
  top: -25px;
  width: 220px;
  @media (--tablet) {
    position: absolute;
    left: auto;
    right: var(--spacing-5);
    top: var(--spacing-5);
    width: 45%;
  }
}

.button {
  @media (--tablet) {
    width: fit-content;
  }
}

.modalContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;

  [data-service-consumer='native'] & {
    height: 100dvh;
  }

  @media (--tablet-l) {
    height: 600px;
    width: 800px;
  }

  & iframe {
    width: 100%;
    height: 100vw;
    border: none;

    [data-service-consumer='native'] & {
      height: 100dvh;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 700px;
  position: relative;

  [data-service-consumer='native'] & {
    min-height: unset;
    height: 100dvh;
  }

  @media (--tablet-l) {
    width: 100%;
  }

  & iframe {
    border: none;
    height: 100vw;

    [data-service-consumer='native'] & {
      height: 100dvh;
    }
  }
}

.modalBody {
  padding: 0;
}
