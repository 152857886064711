.callBackModalWrapper {
  width: 100%;
}

.cta {
  width: 100%;
}

.error {
  color: var(--colour-highlight-700, #e24529);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 32px 0px 20px;
}
