[data-pdp-breadcrumb].backButton {
  &&& {
    color: inherit;
    text-decoration: unset;
    justify-content: flex-start;
    min-height: var(--button-large-height);

    @media (--tablet-l) {
      margin-bottom: -32px;
    }

    @media (--desktop-l) {
      margin-bottom: -64px;
    }
  }
}

.container {
  [data-pdp-breadcrumb-v3].backButton {
    color: inherit;
    text-decoration: unset;
    justify-content: flex-start;
    font-size: 12px;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;

    @media (--tablet-l) {
      margin-bottom: 0;
    }

    @media (--desktop-l) {
      margin-bottom: 0;
    }

    &:after {
      content: '|';
      padding: 0 4px;
      font-size: 14px;
    }
  }
}

.container {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  gap: var(--spacing-2);
  font-size: 14px;

  @media (--mobile) {
    padding: var(--spacing-3) 0;
  }

  @media (--tablet-s) {
    padding: var(--spacing-4) 0;
  }

  @media (--tablet-l) {
    margin-bottom: -32px;
    padding: var(--spacing-6) 0;
  }

  @media (--desktop-l) {
    margin-bottom: -64px;
    padding: var(--spacing-6) 0;
  }
}
