.heroWrapper {
  @media (--tablet-l) {
    align-items: center;
    display: flex !important;
    flex-direction: row !important;
  }

  position: relative;
}

.textWrapper {
  margin: var(--spacing-5) 0 var(--spacing-8) 0;
  @media (--tablet-l) {
    flex: 1;
    max-width: 50%;
  }
}

.mobileImageContainer {
  margin: -12px 0 -20px 0;

  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  @media (--tablet-l) {
    display: none;
  }
}

.imageContainer {
  display: none;

  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 200px;
  }

  @media (--tablet-l) {
    margin: var(--spacing-6) 0 0 0;
    display: block;
    flex: 1;
    max-width: 50%;
  }
}

.formWrapper {
  width: 100%;
  margin-top: -110px;
  background-color: white;
  z-index: 1;
  border-bottom: 1px solid var(--neutral-200, #cdcdcd);

  & iframe {
    border: none;
    height: 100%;
    padding: 24px;
  }
}
