.sliderWrapper {
  & .heycarSlider {
    height: var(--slider-thumb-size);
    display: flex;
    align-items: center;
  }

  & :global(.range-slider__track) {
    top: 10px;
    height: var(--spacing-1);
    background-color: var(--color-primary-200);

    /* section of track in between the 2 thumbs */
    &:global(.range-slider__track-1) {
      background-color: var(--color-primary-700);
    }
  }

  & :global(.single-knob-track) {
    top: 10px;
    height: var(--spacing-1);
    background-color: var(--color-primary-700);
  }

  & :global(.single-knob-track-1) {
    top: 10px;
    height: var(--spacing-1);
    background-color: var(--color-primary-200);
  }

  & :global(.range-slider__thumb) {
    width: var(--slider-thumb-size);
    height: var(--slider-thumb-size);
    border: solid 1px var(--color-primary-700);
    border-radius: 50%;
    background: #fff;
    transform: scale(1);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    user-select: none;
    outline: none;
    cursor: grab;
    filter: drop-shadow(0px 4px 12px rgba(38, 38, 38, 0.08));

    &:focus-visible {
      width: var(--slider-focus-visible);
      height: var(--slider-focus-visible);
      margin-left: var(--slider-thumb-left-offset);
      outline: none;
    }

    & :global(.tooltip) {
      user-select: none;
      position: absolute;
      bottom: 30px;
      opacity: 0;
      transform: scale(0.6) translateY(50%);
      pointer-events: none;
      transition: all 0.3s cubic-bezier(0.6, -0.28, 0.18, 0.94);
      padding: var(--spacing-1);
      background-color: var(--color-primary-50);
      color: var(--color-neutral-700);
      white-space: nowrap;
    }

    /* Size booster for mobile usability */
    &:before {
      content: '';
      width: var(--slider-halo-size);
      height: var(--slider-halo-size);
      position: absolute;
      border-radius: 50%;
      margin-top: -6px;
    }
  }

  & :global(.dragging) {
    cursor: grabbing;
    margin-left: var(--slider-thumb-left-offset);
    width: var(--slider-focus-visible);
    height: var(--slider-focus-visible);

    & :global(.tooltip) {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  &.isWithHistogram {
    & :global(.range-slider__track) {
      height: 2px;
    }
  }

  &.disabled {
    & :global(.range-slider__thumb) {
      cursor: initial;
      border-color: var(--form-control-disabled-bg-color);
    }
    & :global(.range-slider__track),
    &:global(.range-slider__track-1),
    & :global(.single-knob-track),
    & :global(.single-knob-track-1) {
      background-color: var(--form-control-disabled-bg-color);
    }
  }
}
