.connectWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  padding: var(--spacing-4) var(--spacing-5) var(--spacing-5) 0;

  & p {
    line-height: 20px;
    margin-bottom: 0;
  }
  & h3 {
    font-size: 24px;
    margin-bottom: 0;
    @media (--mobile) {
      font-size: 20px;
    }
  }

  & .connects {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    @media (--tablet) and (max-width: 1023px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  & .connectItem {
    display: flex;
    gap: var(--spacing-3);
    padding: var(--spacing-1) 0;
    align-items: start;
  }

  & .connectIcon {
    position: relative;
    aspect-ratio: 1/1;
    height: 20px;
    margin-top: 2px;
  }
  & .connectText {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  & .connectSubHeader {
    & p {
      font-size: 16px;
    }
    margin-bottom: var(--spacing-4);
  }
}

.connectdescription {
  & p {
    font-size: 16px !important;
  }
}
.connectDescriptionText {
  & p {
    font-size: 14px;
  }
  margin-bottom: var(--spacing-4);
}

.connectHeader {
  & p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
  }
  margin-bottom: var(--spacing-3);
  margin-top: -16px;
}

.connectModalCta {
  text-decoration: underline;
  color: var(--color-mica-blue-500);
  font-size: 12px;
  cursor: pointer;
  width: 30%;
}

.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
  }
}

.modalBody {
  overflow: auto;
  & h5 {
    font-size: 20px;
    margin-bottom: 0;
  }

  & p {
    font-size: 16px;
    line-height: 22px !important;
    margin-top: var(--spacing-3);
  }
  margin-bottom: var(--spacing-3);
}
