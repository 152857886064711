.wrapper {
  display: grid;

  grid-template-columns:
    var(--sidePadding) calc(50vw - var(--sidePadding)) calc(50vw - var(--sidePadding))
    var(--sidePadding);
  grid-template-rows: min(19vw, 256px) 40px 1fr;

  place-items: center;

  @media (--mobile) {
    grid-template-columns: var(--sidePadding) 1fr var(--sidePadding);
    grid-template-rows: min-content 34vw 1fr;
  }
}

.background {
  --evBackgroundColor: var(--color-secondary-600);
  --brighterBackgroundColor: --evBackgroundColor;
  --brighterBackgroundColor: oklch(from var(--evBackgroundColor) calc(l * 1.25) c h);
  grid-column: 1 / -1;
  grid-row: 1;
  background-color: var(--evBackgroundColor);

  /* background gradient diagonal 600 -> 400 -> 600 */
  background-image: linear-gradient(
    -45deg,
    var(--evBackgroundColor) 30%,
    var(--brighterBackgroundColor) 60%,
    var(--evBackgroundColor) 90%
  );
  background-size: 200% 100%;
  background-position: 0% 0;
  z-index: -2;
  place-self: stretch;

  transition: 2s ease-out;
  opacity: 1;

  @starting-style {
    background-position: 100% 0;
    background-repeat: repeat;
  }

  @media (--mobile) {
    grid-row: 1/3;
  }
}

.backgroundImage {
  background-size: cover;
  background-position: center;
  z-index: 10;
  place-self: stretch;

  transition: 2s ease-out;
  opacity: 1;

  grid-row: 1 / 3;
  grid-column: 1 / -1;
  margin-bottom: -86px;

  transition: 0.4s linear;
  opacity: 1;

  @starting-style {
    opacity: 0;
    background-size: cover 110%;
  }

  @media (--mobile) {
    grid-row: 1/3;
  }
}

.imageWrapper {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 16/9;
  place-self: stretch;
  height: fit-content;
  margin-top: var(--spacing-3);

  transition: 2s ease-out;
  opacity: 1;

  @starting-style {
    /* opacity: 0; */
    transform: translateX(-10px);
  }

  @media (--mobile) {
    grid-row: 2;
    margin-top: -5vw;
  }
}

.textContent {
  grid-column: 3;
  grid-row: 1;
  display: block;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-3);
  align-content: center;
  padding: var(--spacing-3) 0;

  transition: 2s ease-out;
  opacity: 1;

  @starting-style {
    /* opacity: 0; */
    transform: translateX(10px);
  }

  @media (--mobile) {
    grid-column: 2;
  }

  & h1 {
    background: white;
    color: var(--color-primary-700);
    box-decoration-break: clone;
    padding: 0 10px;
    display: inline;
    margin: 0;
    line-height: 1.6;
    font-size: clamp(26px, 3.5vw, 48px);

    @media (--mobile) {
      font-size: clamp(26px, 6.5vw, 48px);
    }
  }
}

.iconWrapper {
  color: var(--color-mica-blue-700);
  font-size: clamp(20px, 3vw, 35px);
  font-weight: 500;
  padding: 0 var(--spacing-3);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-3);
  margin-bottom: -5px;
}

.heycarAvec {
  align-self: baseline;
}

.heycarIcon {
  position: relative;
  padding: min(0.7vw, var(--spacing-2)) 0 0;

  & svg {
    padding: 0;

    width: auto;
    height: clamp(29px, 4vw, 48px);

    & :global(.logo-text) {
      fill: var(--color-primary-700);
    }

    & :global(.logo-underline) {
      fill: white;
    }
  }
}

.searchSection {
  align-self: stretch;
  grid-column: 2 / 4;
  grid-row: 3;
  display: grid;

  background: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  width: 720px;
  max-width: calc(100vw - 48px);
  position: relative;
  z-index: 20;

  transition: 2s ease-out;
  opacity: 1;
  @media (--mobile) {
    margin-top: 40px;
  }
}

.searchBannerForm {
  min-height: 127px;

  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-3);
  padding: var(--spacing-3);
  min-height: 72px;

  grid-template-columns: 2fr 2fr 2fr 3fr;

  @media (--mobile) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }

  > div {
    width: 100%;
  }
}
